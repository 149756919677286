import React from 'react';
import './About.css';

const About: React.FC = () => {
  return (
    <div className="default-container">
      <h1>About Spencer's Desk</h1>
      <p>
        Welcome to Spencer's Desk! I'm passionate about 3D printing, DIY projects, and sharing my 
        creations with the world. On this platform, you’ll find insights into my latest projects, 
        tutorials, and tools to help you get started with your own creative endeavors.
      </p>
      <p>
        When I'm not tinkering with new designs or creating content, I'm a resident training in
        medical physics. My goal is to inspire others to explore their 
        creativity and take on fun, challenging projects.
      </p>
      <p>
        Thank you for stopping by, and feel free to reach out via the contact page or through my 
        social media links in the footer.
      </p>
    </div>
  );
};

export default About;
