import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import pages, { Page } from '../data/page_data';


const Header: React.FC = () => {
    // Filter out the project-related topics
    const publishedProjectPages: Page[] = pages.filter(
        (page) => page.published && page.project // Only include published pages with the 'printing' flag
    );

    // Sort project pages by created date, newest first
    const sortedProjectPages = [...publishedProjectPages].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured project page and the most recent project page
    const featuredProject = publishedProjectPages.find(
        (page) => page.featured && page.project
    );
    const mostRecentProject = sortedProjectPages[0];



    // Filter out the printing-related topics
    const publishedPrintingPages: Page[] = pages.filter(
        (page: Page) => page.published && page.printing // Only include published pages with the 'printing' flag
    );

    // Sort printing pages by created date, newest first
    const sortedPrintingPages = [...publishedPrintingPages].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured printing page and the most recent printing page
    const featuredPrint = publishedPrintingPages.find(
        (page) => page.featured && page.printing
    );
    const mostRecentPrint = sortedPrintingPages[0];


    const [is3DPrintingOpen, set3DPrintingOpen] = useState(false);
    const [is3DPrintingFadingOut, set3DPrintingFadingOut] = useState(false);

    const [isProjectsOpen, setProjectsOpen] = useState(false);
    const [isProjectsFadingOut, setProjectsFadingOut] = useState(false);

    // Handlers for dropdowns
    const handleDropdownEnter = (setOpen: React.Dispatch<React.SetStateAction<boolean>>, setFadingOut: React.Dispatch<React.SetStateAction<boolean>>) => {
        setFadingOut(false);
        setOpen(true);
    };

    const handleDropdownLeave = (setOpen: React.Dispatch<React.SetStateAction<boolean>>, setFadingOut: React.Dispatch<React.SetStateAction<boolean>>) => {
        setFadingOut(true);
        setTimeout(() => setOpen(false), 300);
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo-title-container">
                    <Link to="/" className="nav-link">
                        <img src="/assets/logo.png" alt="Logo" className="profile-image" />
                    </Link>
                    <Link to="/" className="nav-link">
                        <h1>Spencer's Desk</h1>
                    </Link>
                </div>
                <nav className="nav-buttons">
                    {/* Projects Dropdown */}
                    <div
                        className="dropdown"
                        onMouseEnter={() => handleDropdownEnter(setProjectsOpen, setProjectsFadingOut)}
                        onMouseLeave={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}
                    >
                        <Link to="/projects">
                            <button
                                onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}
                                className={`button ${
                                    isProjectsOpen
                                        ? isProjectsFadingOut
                                            ? 'dropdown-fading-out'
                                            : 'dropdown-open'
                                        : ''
                                }`}
                            >
                                Projects
                            </button>
                        </Link>
                        <div
                            className={`dropdown-menu ${
                                isProjectsOpen
                                    ? isProjectsFadingOut
                                        ? 'fade-out'
                                        : 'show'
                                    : ''
                            }`}
                        >
                            <div className="dropdown-section">
                                <h3>Featured</h3>
                                {featuredProject ? (
                                    <Link to={featuredProject.link}>
                                        <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                            {featuredProject.name}
                                        </button>
                                    </Link>
                                ) : (
                                    <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                        Error
                                    </button>
                                )}
                                
                            </div>
                            <div className="dropdown-separator"></div>
                            <div className="dropdown-section">
                                <h3>Most Recent</h3>
                                <Link to={mostRecentProject.link}>
                                    <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                        {mostRecentProject.name}
                                    </button>
                                </Link>
                            </div>
                            <div className="dropdown-separator"></div>
                            <div className="dropdown-section">
                                <h3>Categories</h3>
                                <Link to="/projects/electronics">
                                    <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                        Electronics
                                    </button>
                                </Link>
                                <Link to="/projects/software">
                                    <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                        Software
                                    </button>
                                </Link>
                                <Link to="/projects/design">
                                    <button onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}>
                                        Design
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* 3D Printing Dropdown */}
                    <div
                        className="dropdown"
                        onMouseEnter={() => handleDropdownEnter(set3DPrintingOpen, set3DPrintingFadingOut)}
                        onMouseLeave={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}
                    >
                        <Link to="/3dprinting">
                            <button
                                onClick={() => handleDropdownLeave(setProjectsOpen, setProjectsFadingOut)}
                                className={`button ${
                                    is3DPrintingOpen
                                        ? is3DPrintingFadingOut
                                            ? 'dropdown-fading-out'
                                            : 'dropdown-open'
                                        : ''
                                }`}
                            >
                                3D Printing
                            </button>
                        </Link>
                        <div
                            className={`dropdown-menu ${
                                is3DPrintingOpen
                                    ? is3DPrintingFadingOut
                                        ? 'fade-out'
                                        : 'show'
                                    : ''
                            }`}
                        >
                            <div className="dropdown-section">
                                <h3>Featured</h3>
                                {featuredPrint ? (
                                <Link to={featuredPrint.link}>
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        {featuredPrint.name}
                                    </button>
                                </Link>
                                ) : (
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        Error
                                    </button>
                                )}
                            </div>
                            <div className="dropdown-separator"></div>
                            <div className="dropdown-section">
                                <h3>Most Recent</h3>
                                <Link to={mostRecentPrint.link}>
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        {mostRecentPrint.name}
                                    </button>
                                </Link>
                            </div>
                            <div className="dropdown-separator"></div>
                            <div className="dropdown-section">
                                <h3>Categories</h3>
                                <Link to="/3dprinting/mods">
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        Mods
                                    </button>
                                </Link>
                                <Link to="/3dprinting/calibration">
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        Calibration
                                    </button>
                                </Link>
                                <Link to="/3dprinting/materials">
                                    <button onClick={() => handleDropdownLeave(set3DPrintingOpen, set3DPrintingFadingOut)}>
                                        Materials
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Separator */}
                    <div className="nav-separator"></div>

                    {/* Other Nav Links */}
                    <Link to="/about">
                        <button>About</button>
                    </Link>
                    <Link to="/contact">
                        <button>Contact</button>
                    </Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;
