import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import pages, { Page } from '../../data/page_data';
import './ThreeDPrinting.css';

const ThreeDPrinting: React.FC = () => {
    // Filter out the printing-related topics
    const publishedPrintingPages: Page[] = pages.filter(
        (page: Page) => page.published && page.printing // Only include published pages with the 'printing' flag
    );

    // Sort printing pages by created date, newest first
    const sortedPrintingPages = [...publishedPrintingPages].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured printing page and the most recent printing page
    const featuredPrint = publishedPrintingPages.find(
        (page) => page.featured && page.printing
    );
    const mostRecentPrint = sortedPrintingPages[0];

    // Filter out featured and most recent topics from the rest
    const otherPrints = publishedPrintingPages.filter(
        (page) => page.id !== featuredPrint?.id && page.id !== mostRecentPrint?.id
    );

    return (
        <div className="default-container printing-container">
            <h1>3D Printing</h1>
            <div className="cards-container">
                {featuredPrint && (
                    <Link to={featuredPrint.link} className="card">
                        <h2>Featured</h2>
                        <img
                            src={featuredPrint.image}
                            alt={featuredPrint.name}
                            className="card-thumbnail"
                        />
                        <h3>{featuredPrint.name}</h3>
                        <p>{featuredPrint.description}</p>
                    </Link>
                )}
                {mostRecentPrint && (
                    <Link to={mostRecentPrint.link} className="card">
                        <h2>Most Recent</h2>
                        <img
                            src={mostRecentPrint.image}
                            alt={mostRecentPrint.name}
                            className="card-thumbnail"
                        />
                        <h3>{mostRecentPrint.name}</h3>
                        <p>{mostRecentPrint.description}</p>
                    </Link>
                )}
            </div>

            {/* Categories Section */}
            <h1>Categories</h1>
            <div className="cards-container">
                <Link to="/3dprinting/mods" className="card">
                    <h3>Mods</h3>
                    <img
                        src="/assets/mods_page.png"
                        alt="Printer Mods Thumbnail"
                        className="card-thumbnail"
                    />
                    <p>Learn about some cool printer mods!</p>
                </Link>
                <Link to="/3dprinting/calibration" className="card">
                    <h3>Calibration</h3>
                    <img
                        src="/assets/calibration_page.png"
                        alt="Calibration Thumbnail"
                        className="card-thumbnail"
                    />
                    <p>Info on calibrating your printer and filament!</p>
                </Link>
                <Link to="/3dprinting/materials" className="card">
                    <h3>Materials</h3>
                    <img
                        src="/assets/materials_page.png"
                        alt="Materials Thumbnail"
                        className="card-thumbnail"
                    />
                    <p>All about 3D printed filaments and resins!</p>
                </Link>
            </div>

            {/* Featured Topic Section */}
            {featuredPrint && (
                <>
                    <h2>Featured Topic</h2>
                    <div className="cards-container">
                        <Link to={featuredPrint.link} className="card">
                            <h3>{featuredPrint.name}</h3>
                            <img
                                src={featuredPrint.image}
                                alt={`${featuredPrint.name} Thumbnail`}
                                className="card-thumbnail"
                            />
                            <p>{featuredPrint.description}</p>
                        </Link>
                    </div>
                </>
            )}

            {/* Most Recent Topic Section */}
            {featuredPrint && featuredPrint.id !== featuredPrint?.id && (
                <>
                    <h2>Most Recent Topic</h2>
                    <div className="cards-container">
                        <Link to={featuredPrint.link} className="card">
                            <h3>{featuredPrint.name}</h3>
                            <img
                                src={featuredPrint.image}
                                alt={`${featuredPrint.name} Thumbnail`}
                                className="card-thumbnail"
                            />
                            <p>{featuredPrint.description}</p>
                        </Link>
                    </div>
                </>
            )}

            {/* Other Topics Section */}
            <h1>Other Topics</h1>
            <div className="cards-container">
                {otherPrints.map((topic: Page) => (
                    <Link to={topic.link} key={topic.id} className="card">
                        <h3>{topic.name}</h3>
                        <img
                            src={topic.image}
                            alt={`${topic.name} Thumbnail`}
                            className="card-thumbnail"
                        />
                        <p>{topic.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ThreeDPrinting;
