import React from "react";

interface YouTubeEmbedProps {
    videoId: string;
    width?: string;
    height?: string;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId, width = "560", height = "315" }) => {
    return (
        <div style={{ position: "relative", paddingTop: "40px", paddingBottom: "56.25%", height: 0 }}>
            <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                style={{
                    // position: "absolute",
                    top: 0,
                    left: 0,
                    width: width,
                    height: height,
                    border: 0,
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
