import React from "react";
import "./Contact.css";

const Contact: React.FC = () => {
  return (
    <div className="default-container">
      <h1>Contact Me</h1>
      <div className="contact-info">
        <p>
          If you’d like to get in touch, feel free to email me at:{" "}
          <a href="mailto:spencer@spencersdesk.com" className="email-link">
            spencer@spencersdesk.com
          </a>
          {" "}or join the Spencer's Desk{" "}
          <a href="https://discord.com/invite/3Sz4ExTqy4" className="email-link" target="_blank" rel="noopener noreferrer">Discord</a>
          !
        </p>
        <p>
          Whether it's feedback, questions, or just saying hello, I'd love to
          hear from you!
        </p>
      </div>
    </div>
  );
};

export default Contact;