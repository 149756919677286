import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './ThreeMeloDPage.css';
import PageHeader from '../../components/PageHeader';
import YouTubeEmbed from "../../components/YouTubeEmbed";


interface Printer {
    id: number;
    printerName: string;
    xAng: number;
    yAng: number;
    zAng: number;
    xRpr: number;
    yRpr: number;
    zRpr: number;
    xDim: number;
    yDim: number;
    zDim: number;
}

interface Song {
    id: number;
    songName: string;
    songPath: string;
}

const ThreeMeloDPage: React.FC = () => {
    const [printers, setPrinters] = useState<Printer[]>([]);
    const [songs, setSongs] = useState<Song[]>([]);
    const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null);
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);

    const [startGcode, setStartGcode] = useState<string>(''); // State to store G-code
    const [startPositions, setStartPositions] = useState({
        xStart: 0,
        yStart: 0,
        zStart: 10,
    });
    const [octaveAdjustments, setOctaveAdjustments] = useState({
        melody: 0,
        mid: 0,
        bass: 0,
    });
    const [overrides, setOverrides] = useState<{ [key: string]: number | null }>({});


    // Error handling
    const [, setError] = useState<string | null>(null);

    const [isOpen, setIsOpen] = useState(false);

    // Fetch printers and songs from the backend
    useEffect(() => {
        const fetchPrinters = async () => {
            try {
                const response = await fetch('https://spencersdesk.com/api/printers');
                if (!response.ok) throw new Error('Failed to fetch printers');
                const data = await response.json();
                setPrinters(data);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        const fetchSongs = async () => {
            try {
                const response = await fetch('https://spencersdesk.com/api/songs');
                if (!response.ok) throw new Error('Failed to fetch songs');
                const data = await response.json();
                setSongs(data);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        fetchPrinters();
        fetchSongs();
    }, []);

    // Handle printer changes
    const handlePrinterChange = (option: { value: number; label: string } | null) => {
        const selected = printers.find((p) => p.id === option?.value) || null;
        setSelectedPrinter(selected);

        // Update start positions dynamically
        if (selected) {
            setStartPositions({
                xStart: selected.xDim / 2,
                yStart: selected.yDim / 2, // Default Y position
                zStart: selected.zDim / 5,
            });
        } else {
            setStartPositions({ xStart: 0, yStart: 0, zStart: 10 }); // Reset if no printer selected
        }
    };


    // Handle song changes
    const handleSongChange = (option: { value: number; label: string } | null) => {
        const selected = songs.find((s) => s.id === option?.value) || null;
        setSelectedSong(selected);
    };


    // Handle start position changes
    const handleStartPositionChange = (
        axis: 'xStart' | 'yStart' | 'zStart',
        value: string
    ) => {
        setStartPositions((prev) => ({
            ...prev,
            [axis]: parseFloat(value) || 0, // Handle empty or invalid input
        }));
    };

    // Handle changes in the override inputs
    const handleOverrideChange = (key: string, value: string) => {
        setOverrides((prevOverrides) => ({
            ...prevOverrides,
            [key]: value === '' ? null : parseFloat(value), // Set to null if empty
        }));
    };

    // Handle changes in octave adjustments
    const handleOctaveChange = (e: React.ChangeEvent<HTMLInputElement>, part: string) => {
        setOctaveAdjustments((prev) => ({
            ...prev,
            [part]: parseInt(e.target.value), // Update specific part (melody, mid, bass)
        }));
    };

    // Handle reset fields
    const resetInputs = () => {
        setSelectedPrinter(null);
        setSelectedSong(null);
        setOverrides({});
        setStartGcode('');
        setOctaveAdjustments({ melody: 0, mid: 0, bass: 0 });
        setStartPositions({ xStart: 0, yStart: 0, zStart: 10 });
    };



    const toggleSettings = () => {
        setIsOpen(!isOpen);
    };




    // Handle form submission and send data to the backend
    const run3MeloD = async () => {
        if (selectedPrinter && selectedSong) {

            // Default G-code to use as a fallback
            const defaultGcode = `G28
G1 X${startPositions.xStart} Y${startPositions.yStart} Z${startPositions.zStart} F2000
G4 P1000
M118 Now playing: ${selectedSong.songName || ''}`;
        
            // Use `startGcode` or fallback to the default if it's empty or whitespace
            const gcodeToSend = startGcode.trim() ? startGcode : defaultGcode;

            const bodyData = {
                printerId: selectedPrinter.id,
                songId: selectedSong.id,
                overrides: Object.fromEntries(
                    Object.entries(overrides).filter(([, value]) => value !== null)
                ), // Only include non-null overrides
                startPositions: startPositions, // Add start position to the request
                startGcode: gcodeToSend, // Use the processed G-code
                octaveAdjustments: octaveAdjustments, // Add octave adjustments
            };

            try {
                const response = await fetch('https://spencersdesk.com/api/run-3meloD', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyData),
                });

                if (!response.ok) {
                    throw new Error('Failed to run 3MeloD');
                }

                // Handle successful G-code generation
                // Create a blob from the response
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // Create a link element for downloading
                const a = document.createElement('a');
                a.href = url;

                // Dynamically set the filename based on the song name, ensuring it's valid for a file
                const sanitizedSongName = selectedSong.songName.replace(/[^a-zA-Z0-9-_]/g, '_'); // Replace invalid characters
                a.download = `${sanitizedSongName || 'output'}.gcode`; // Fallback to 'output' if name is missing

                document.body.appendChild(a);
                a.click();
                a.remove(); // Clean up the link element
                window.URL.revokeObjectURL(url); // Free up memory

            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert("Please select both a printer and a song.");
        }
    };





    return (
        <div className="default-container three-melod-page">
            <PageHeader title="3MeloD" />
            <h2>Select Printer and Song</h2>

            {/* Select Printer Dropdown */}
            <label htmlFor="printerDropdown"></label>
            <Select
                id="printerDropdown"
                classNamePrefix="react-select"
                placeholder="-- Choose a Printer --"
                options={printers.map((printer) => ({
                    value: printer.id,
                    label: printer.printerName,
                }))}
                value={selectedPrinter ? { value: selectedPrinter.id, label: selectedPrinter.printerName } : null}
                onChange={handlePrinterChange} // Handle printer selection
            />

            <br />

            {/* Select Song Dropdown */}
            <label htmlFor="songDropdown"></label>
            <Select
                id="songDropdown"
                classNamePrefix="react-select"
                placeholder="-- Choose a Song --"
                options={songs.map((song) => ({ value: song.id, label: song.songName }))}
                value={selectedSong ? { value: selectedSong.id, label: selectedSong.songName } : null}
                onChange={handleSongChange}
            />

            <br />

            <div className="button-container">
                {/* Button to Run 3MeloD */}
                <button type="button" onClick={run3MeloD}>
                    <i className="fas fa-play"></i> Run 3MeloD
                </button>

                {/* Button to Reset */}
                <button type="button" onClick={resetInputs}>
                    <i className="fas fa-sync-alt"></i> Reset
                </button>

                {/* Button to toggle settings */}
                <button onClick={toggleSettings}>
                    <i className="fas fa-cogs"></i> {isOpen ? 'Hide Settings' : 'Open Settings'}
                </button>
            </div>


            {/* Settings section */}
            <div className={`settings-section ${isOpen ? 'settings-visible' : 'settings-hidden'}`}>
                <h2>Settings</h2>

                {/* Start settings section */}
                <div className="start-container">
                    <div>
                        <h3>Start Position</h3>
                            <form>
                                <label htmlFor="x-start">X-Axis: </label>
                                <input
                                    type="number"
                                    id="x-start"
                                    value={startPositions.xStart}
                                    onChange={(e) => handleStartPositionChange('xStart', e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                                <span> mm</span>
                                <br />

                                <label htmlFor="y-start">Y-Axis: </label>
                                <input
                                    type="number"
                                    id="y-start"
                                    value={startPositions.yStart}
                                    onChange={(e) => handleStartPositionChange('yStart', e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                                <span> mm</span>
                                <br />

                                <label htmlFor="z-start">Z-Axis: </label>
                                <input
                                    type="number"
                                    id="z-start"
                                    value={startPositions.zStart}
                                    onChange={(e) => handleStartPositionChange('zStart', e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                                <span> mm</span>
                            </form>
                    </div>

                    <div>
                        <h3>Start Gcode</h3>
                        {/* Start Gcode Text Area */}
                        <label htmlFor="startGcode"></label>
                        <textarea
                            id="startGcode"
                            value={startGcode}
                            onChange={(e) => setStartGcode(e.target.value)} // Update state with user input
                            rows={5}
                            cols={50}
                            placeholder={`G28
G1 X${startPositions.xStart} Y${startPositions.yStart} Z${startPositions.zStart} F2000
G4 P1000
M118 Now playing: ${selectedSong?.songName || ''}`}
                        />
                    </div>
                </div>


                <div className="override-container">
                    {/* Group 1: Rotation per Step */}
                    <div>
                        <h3>Rotation per Step</h3>
                        {['xAng', 'yAng', 'zAng'].map((param) => (
                            <div key={param}>
                                <label htmlFor={param}>{param}: </label>
                                <input
                                    type="number"
                                    id={param}
                                    value={overrides[param] ?? ''}
                                    onChange={(e) => handleOverrideChange(param, e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                                <span> &deg;</span>
                            </div>
                        ))}
                    </div>

                    {/* Group 2: Distance Traveled per Full Revolution */}
                    <div>
                        <h3>Distance per Revolution</h3>
                        {['xRpr', 'yRpr', 'zRpr'].map((param) => (
                            <div key={param}>
                                <label htmlFor={param}>{param}: </label>
                                <input
                                    type="number"
                                    id={param}
                                    value={overrides[param] ?? ''}
                                    onChange={(e) => handleOverrideChange(param, e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                                <span> mm</span>
                            </div>
                        ))}
                    </div>

                    {/* Group 3: Printer Dimensions */}
                    <div>
                        <h3>Printer Dimensions</h3>
                        {['xDim', 'yDim', 'zDim'].map((param) => (
                            <div key={param}>
                                <label htmlFor={param}>{param}: </label>
                                <input
                                    type="number"
                                    id={param}
                                    value={overrides[param] ?? ''}
                                    onChange={(e) => handleOverrideChange(param, e.target.value)}
                                    className="small-input" // Apply the small-input class
                                />
                            <span> mm</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="start-container">
                {/* Octave Adjustments Inputs */}
                    <div>
                        <h3>Octave Adjustments</h3>
                        <label htmlFor="melody-octave">Melody: </label>
                        <input
                            type="number"
                            id="melody-octave"
                            value={octaveAdjustments.melody}
                            onChange={(e) => handleOctaveChange(e, 'melody')} // Handle melody octave change
                            className="small-input" // Apply the small-input class
                        />
                        <br />

                        <label htmlFor="mid-octave">Mid:    </label>
                        <input
                            type="number"
                            id="mid-octave"
                            value={octaveAdjustments.mid}
                            onChange={(e) => handleOctaveChange(e, 'mid')} // Handle mid octave change
                            className="small-input" // Apply the small-input class
                        />
                        <br />

                        <label htmlFor="bass-octave">Bass:   </label>
                        <input
                            type="number"
                            id="bass-octave"
                            value={octaveAdjustments.bass}
                            onChange={(e) => handleOctaveChange(e, 'bass')} // Handle bass octave change
                            className="small-input" // Apply the small-input class
                        />
                        <br />
                    </div>
                </div>

            </div>

            <br />

            <div className="resources">
                <div>
                    <h1>Resources</h1>
                </div>
                <div className="links-container">
                    <a href="https://github.com/Spencers-Desk/3MeloD_Songs/tree/main" target="_blank" className="github-link">
                        <img src="/assets/3meloD-songs.png" alt="3MeloD Songs Image" className="github-image" />
                    </a>
                    <a href="https://github.com/Spencers-Desk/3MeloD/tree/main" target="_blank" className="github-link">
                        <img src="/assets/3meloD.png" alt="3MeloD Image" className="github-image" />
                    </a>
                    <a href="https://discord.com/invite/3Sz4ExTqy4" target="_blank" className="discord-link">
                        <i className="fab fa-discord"></i> Discord
                    </a>
                </div>
                <div className="youtube-video">
                    <YouTubeEmbed videoId="RTDE6RPTMqA" width="600px" height="300px" />
                </div>
            </div>
        </div>
    );
};

export default ThreeMeloDPage;
