import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import pages, { Page } from '../../data/page_data'; // Import the projects data
import './Projects.css';

const Projects: React.FC = () => {
    // Filter out the project-related topics
    const publishedProjectPages: Page[] = pages.filter(
        (page) => page.published && page.project // Only include published pages with the 'project' flag
    );

    // Sort project pages by created date, newest first
    const sortedProjectPages = [...publishedProjectPages].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured project page and the most recent project page
    const featuredProject = publishedProjectPages.find(
        (page) => page.featured && page.project
    );
    const mostRecentProject = sortedProjectPages[0];

    // Filter out featured and most recent topics from the rest
    const otherProjects = publishedProjectPages.filter(
        (page) => page.id !== featuredProject?.id && page.id !== mostRecentProject?.id
    );

    return (
        <div className="default-container projects-container">
            <h1>Projects</h1>
            <div className="cards-container">
                {/* Featured Project */}
                {featuredProject && (
                    <Link to={featuredProject.link} className="card">
                        <h2>Featured</h2>
                        <img
                            src={featuredProject.image}
                            alt={`${featuredProject.name} Thumbnail`}
                            className="card-thumbnail"
                        />
                        <h3>{featuredProject.name}</h3>
                        <p>{featuredProject.description}</p>
                    </Link>
                )}

                {/* Most Recent Project */}
                {mostRecentProject && mostRecentProject.id !== featuredProject?.id && (
                    <Link to={mostRecentProject.link} className="card">
                        <h2>Most Recent</h2>
                        <img
                            src={mostRecentProject.image}
                            alt={`${mostRecentProject.name} Thumbnail`}
                            className="card-thumbnail"
                        />
                        <h3>{mostRecentProject.name}</h3>
                        <p>{mostRecentProject.description}</p>
                    </Link>
                )}
            </div>

            <h1>All Projects</h1>

            {/* Rest of the Projects */}
            <div className="cards-container">
                {otherProjects.map((project: Page) => (
                    <Link to={project.link} key={project.id} className="card">
                        <h2>{project.name}</h2>
                        <img
                            src={project.image}
                            alt={`${project.name} Thumbnail`}
                            className="card-thumbnail"
                        />
                        <p>{project.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Projects;
