import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import pages from '../data/page_data';

const Home: React.FC = () => {
     // Filter published projects
     const publishedProjects = pages.filter(
        (page) => page.published && page.project // Only include projects that are published
    );

    // Sort projects by created date, newest first
    const sortedProjects = [...publishedProjects].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured project and the most recent project
    const featuredProject = publishedProjects.find((page) => page.featured && page.project);
    const mostRecentProject = sortedProjects[0];

    // Filter 3D printing topics (pages with category '3DPrinting')
    const publishedPrintingPages = pages.filter(
        (page) => page.published && page.printing // Only include published pages with the 'printing' flag
    );

    // Sort printing pages by created date, newest first
    const sortedPrintingPages = [...publishedPrintingPages].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // Find the featured printing page and the most recent printing page
    const featuredPrint = publishedPrintingPages.find(
        (page) => page.featured && page.printing
    );
    const mostRecentPrint = sortedPrintingPages[0];

    return (
        <div className="default-container">
            <h1>Welcome to Spencer's Desk</h1>
            {/* Hero Section */}
            <div className="home-content">
                <img
                    src="/assets/homepage.png"
                    alt="Spencer's Desk"
                    className="home-image"
                />
                <p>
                    Dive into my latest projects, tutorials, and explorations in tech and creativity. Whether you're
                    looking for inspiration or detailed guides, you've come to the right place.
                </p>
            </div>

            {/* Projects Section */}
            <section className="section projects-section">
                <h2>Explore My Projects</h2>
                <div className="cards-container">
                    <Link to="/projects" className="card">
                        <h2>Projects</h2>
                        <img
                            // src="/assets/projects-thumbnail"
                            alt="Projects Thumbnail"
                            className="card-thumbnail"
                        />
                        <p>View all my projects in one place.</p>
                    </Link>
                    {featuredProject && (
                        <Link to={featuredProject.link} className="card">
                            <h2>Featured</h2>
                            <img
                                src={featuredProject.image}
                                alt={featuredProject.name}
                                className="card-thumbnail"
                            />
                            <h3>{featuredProject.name}</h3>
                            <p>{featuredProject.description}</p>
                        </Link>
                    )}
                    {mostRecentProject && (
                        <Link to={mostRecentProject.link} className="card">
                            <h2>Most Recent</h2>
                            <img
                                src={mostRecentProject.image}
                                alt={mostRecentProject.name}
                                className="card-thumbnail"
                            />
                            <h3>{mostRecentProject.name}</h3>
                            <p>{mostRecentProject.description}</p>
                        </Link>
                    )}
                </div>
            </section>

            {/* 3D Printing Section */}
            <section className="section printing-section">
                <h2>Dive into 3D Printing</h2>
                <div className="cards-container">
                    <Link to="/3dprinting" className="card">
                        <h2>3D Printing</h2>
                        <img
                            // src="/assets/3Dprinting_page.png"
                            alt="3D Printing Page Thumbnail"
                            className="card-thumbnail"
                        />
                        <p>All about 3D printing!</p>
                    </Link>
                    {featuredPrint && (
                        <Link to={featuredPrint.link} className="card">
                            <h2>Featured</h2>
                            <img
                                src={featuredPrint.image}
                                alt={featuredPrint.name}
                                className="card-thumbnail"
                            />
                            <h3>{featuredPrint.name}</h3>
                            <p>{featuredPrint.description}</p>
                        </Link>
                    )}
                    {mostRecentPrint && (
                        <Link to={mostRecentPrint.link} className="card">
                            <h2>Most Recent</h2>
                            <img
                                src={mostRecentPrint.image}
                                alt={mostRecentPrint.name}
                                className="card-thumbnail"
                            />
                            <h3>{mostRecentPrint.name}</h3>
                            <p>{mostRecentPrint.description}</p>
                        </Link>
                    )}
                </div>
            </section>
        </div>
    );
};

export default Home;
