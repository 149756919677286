// Dependencies
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'; // Adjust the path as needed

// Styling
import './App.css';

// Fundamental Pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Contact from './pages/Contact';

// Components
import Header from './components/Header';
import Footer from './components/Footer';

// Projects Pages
import Projects from './pages/Projects/Projects';
// import Electronics from './pages/Projects/Electronics';
// import Software from './pages/Projects/Software'
// import Design from './pages/Projects/Design'

// 3D_Printing Pages
import ThreeDPrinting from './pages/ThreeDPrinting/ThreeDPrinting';
// import Mods from './pages/ThreeDPrinting/Mods'
// import Calibration from './pages/ThreeDPrinting/Calibration'
// import Materials from './pages/ThreeDPrinting/Materials'

import ThreeMeloDPage from './pages/Projects/ThreeMeloDPage'
import SpencersDeskSite from './pages/Projects/SpencersDeskSite'

const App: React.FC = () => {
    return (
        <div className="App">
            <Header /> {/* Render the Header component here */}
            <ScrollToTop />
            <div className="main-content">
                <Routes>
                    {/* Fundamental Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />

                    {/* Projects Page */}
                    <Route path="/projects" element={<Projects />} />
                    {/* <Route path="/projects/electronics" element={<Electronics />} />
                    <Route path="/projects/software" element={<Software />} />
                    <Route path="/projects/design" element={<Design />} /> */}

                    <Route path="/projects/3MeloD" element={<ThreeMeloDPage />} />
                    <Route path="/projects/website" element={<SpencersDeskSite />} />

                    {/* 3D Printing Page */}
                    <Route path="/3dprinting" element={<ThreeDPrinting />} />
                    {/* <Route path="/3dprinting/mods" element={<Mods />} />
                    <Route path="/3dprinting/calibration" element={<Calibration />} />
                    <Route path="/3dprinting/materials" element={<Materials />} /> */}

                    {/* 404 Not Found */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer /> {/* Render the Footer component here */}
        </div>
    );
};

export default App;
