export interface Page {
    id: string;
    name: string;
    description: string;
    image: string;
    link: string;
    createdAt: string;
    project: boolean;
    printing: boolean;
    category: string[];
    featured: boolean;
    published: boolean;
}

const pages: Page[] = [
    {
        id: '3meloD',
        name: '3MeloD',
        description: 'Turn your 3D Printer into a musical instrument!',
        image: '../assets/3meloD.png',
        link: '/projects/3meloD',
        createdAt: '2024-12-02',
        project: true,
        printing: true,
        category: ['Software'],
        featured: false,
        published: true,
    },
    {
        id: 'website',
        name: "spencersdesk.com",
        description: 'All about the website you are currently interacting with!',
        image: '../assets/website.png',
        link: '/projects/website',
        createdAt: '2024-12-01',
        project: true,
        printing: false,
        category: ['Software'],
        featured: true,
        published: true,
    },
];

export default pages;
